module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vozrogdenie","short_name":"Vozrogdenie","start_url":"/","lang":"uk","background_color":"#FFFFFF","display":"fullscreen","icon":"src/images/vozrogdenie-icon-512.png","localize":[{"start_url":"/en/","lang":"en"},{"start_url":"/ru/","lang":"ru"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2359e30cf49cb86c0d5e01e784707bd8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["uk","en","ru"],"defaultLanguage":"uk","siteUrl":"http://localhost:8000/","i18nextOptions":{"fallbackLng":"uk","lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
